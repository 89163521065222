/*
 Scss Libraries, mixins, functions and any Scss focused logic
*/
/*
* Variables are in a map in settings.scss:

    'small'  : 48em,
    'medium' : 56.25em,
    'large'  : 68.75em,

* Use like this:

.foo {
  color: red;
  @include respond-to('small') {
    color: blue;
  }
}
*/
/*
    $FUNCTIONS
\*




/*
 * Function to keep font size consistent
 .selector { font-size: fs(x-small);}
 * You can see the current values and variables in settings.scss
 */
/*
 * Function to control Index
 	.selector { z-index: z(goku);}
 * Compiles:
	.selector { z-index: 9001;}
 * The MAP used for this is in constants.scss
 */
/*
 Settings for differents elements
*/
/*
    $CONSTANTS
\*
/**
 * Constant layout values, apart from these we can also declare values
 * for different blocks sizes and more.
 */
:root {
  --top-menu-height: 65px;
}

/*
 * A simple solution to manage z-index
 * We implement it with the z($layers) function:
 .element {
  z-index: z(goku);
 }
 *
 */
/**
 * "Start with the small screen first, then expand until it looks like shit. Time to insert a breakpoint!"
 * We use it with a respond-to($breakpoint) mixin:

.text {
  color: pink;
  @include respond-to(medium) {
    color: maroon;
  }
}
 *
 */
/*
    $COLORS
\*
/**
 * Color Palette
 */
/**
 * Item Quality colors
 */
/**
 * Message Colors
 */
/**
 * SS Colors
 */
/*
    $FONTS
\*
/*
 * Font Size variables are defined in a Scss MAP,
 * TODO: this MAP will create a class (in `utilities.scss`)
 * for every size with the value in REM units, you can check
 * `utilities.scss` if you need to change the loop:

 .xrs-small { font-size: .8rem}

 * We also have a function to assign font sizes, call `fs`:

  .selector { font-size: fs(x-small);}

 */
/*
 * Fonts definitions
 */
.saved-cards {
  padding: 0;
  background: transparent;
  margin-bottom: 12px;
}
.saved-cards--old .saved-cards__row {
  background: #41414d;
}
.saved-cards__row, .saved-cards__header {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 38px 180px 1fr 75px 52px;
  padding: 6px 0;
}
.saved-cards__header {
  color: #a5a5b4;
  grid-template-areas: "cards cards name expiration .";
  align-items: flex-end;
}
@media (max-width: 539px) {
  .saved-cards__header {
    display: none;
  }
}
.saved-cards__header__cards {
  grid-area: cards;
  font-weight: bold;
  color: #fff;
}
.saved-cards__header__name {
  grid-area: name;
}
.saved-cards__header__expiration {
  grid-area: expiration;
  text-align: right;
}
.saved-cards__row {
  align-items: center;
  grid-template-areas: "radio card name expiration options";
  background: #22222a;
}
@media (max-width: 539px) {
  .saved-cards__row {
    grid-template-columns: 44px 1fr 52px;
    grid-template-areas: "radio card options" "radio name options" "radio expiration options";
    grid-gap: 6px;
    margin-bottom: 6px;
  }
}
.saved-cards__radio {
  grid-area: radio;
  padding-left: 12px;
}
@media (min-width: 33.75em) {
  .saved-cards__radio {
    padding-left: 24px;
  }
}
.saved-cards__card {
  grid-area: card;
  text-transform: capitalize;
}
.saved-cards__name {
  grid-area: name;
  max-height: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.saved-cards__expiration {
  grid-area: expiration;
}
@media (min-width: 33.75em) {
  .saved-cards__expiration {
    text-align: right;
  }
}
.saved-cards__options {
  grid-area: options;
  padding-right: 12px;
}
@media (min-width: 33.75em) {
  .saved-cards__options {
    justify-content: flex-end;
  }
}
@media (max-width: 539px) {
  .saved-cards [data-title]::before {
    content: attr(data-title);
    color: #a5a5b4;
    margin-right: 6px;
  }
}